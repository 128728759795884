import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";

// ***Page Description***
// This is the page that displays all students in table format
// Clicking on spring/fall semester shows hours for respective time periods for student 
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      students: {},
      studentsKeys: [],
      name: "",
      desc: "",
      value: "",
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch("https://api.peshnhs.org/getData", {
          headers: {
            idtoken: idToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              this.setState({
                students: data.students,
                studentsKeys: data.array,
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.5" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.6" + error
        );
      });
  };

  renderTable = () => {
    if (this.state.studentsKeys.length > 0) {
      return (
        <>
          {this.state.studentsKeys.map((value, index) => {
            return (
              <tr
                class={
                  this.state.students[value].submitted ? null : "bg-yellow-100"
                }
              >
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.students[value].firstname}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].lastname}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].idnumber}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].tshirt}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].gradelevel}
                </td>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                  {this.state.students[value].pisd}
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <a
                    href={"mailto:" + this.state.students[value].emailaddress}
                    class="focus:outline-none"
                  >
                    Email
                  </a>
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                Logs:
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <NavLink
                    to={{
                      pathname: "/creditFall/" + value,
                      aboutProps: {
                        name:
                          this.state.students[value].firstname +
                          this.state.students[value].lastName,
                      },
                    }}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Fall
                  </NavLink>
                </td>
                <td class="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                  <NavLink
                    to={{
                      pathname: "/creditSpring/" + value,
                      aboutProps: {
                                            name:
                          this.state.students[value].firstname +
                          this.state.students[value].lastName,
                      },
                    }}
                    className="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Spring
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </>
      );
    }
  };

  render() {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Student Viewer
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Add Student Credits
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Click the "Edit" indicator to open the student's
                          Credit Log.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <h2 className="text-lg font-semibold text-gray-900 ml-2 mb-2">
                    All Students
                  </h2>
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            First Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Last Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            ID
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            T-Shirt
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Grade
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            PISD
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    }
}
