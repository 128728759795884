import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'; import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { withRouter } from "react-router";
// ***Page Description***
// Fall credit log for students, which can be accessed through students tab and hitting a semester 
// Hours can be individually accepted or rejected here, as well as viewed 
// ***********************

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalNote: false,
      credits: {},
      creditsKeys: [],
      name: "",
      desc: "",
      value: "",
      file: "",
      total: "",
      editing: false,
      editInd: null,
      title: "",
      description: "",
      hours: 0,
    };
  }

  grantHoursIndividual = () => {
    if (this.state.title !== "" && this.state.description !== "" && this.state.hours !== 0) {
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          console.log(this.state.title + ": " + this.state.description + " (" + this.state.hours + ")");

          fetch("https://api.peshnhs.org/grantHoursIndividual?" +
            "title=" + this.state.title +
            "&description=" + this.state.description +
            "&hours=" + this.state.hours +
            "&email=" + this.props.match.params.uid +
            "&sem=credits", {
            method: "GET",
            headers: {
              'idtoken': idToken,
            }
          })
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              if (data.success) {
                console.log('Hours updated successfully!');
                this.fetchData();
                this.renderTable();
              } else {
                console.log('Failed to update hours');
              }
            })
            .catch((error) => {
              console.error('Error making the request:', error);
            });
        });
    }
  }
  componentDidMount = () => {
    this.fetchData();
    console.log("uid" + this.props.match.params.uid);
  };

  fetchData = () => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/userLookupFall?uid=" +
          this.props.match.params.uid,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              var total = await this.getTotal(data.result);
              console.log(total);
              this.setState({
                credits: data.result,
                creditsKeys: Object.keys(data.result),
                name: data.name,
                total: total,
              });
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.2" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.3" + error
        );
      });
  };

  submitCredit = (credit) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/submitCreditAdmin?uid=" +
          this.props.match.params.uid +
          "&name=" +
          credit,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              this.fetchData();
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.4" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.5" + error
        );
      });
  };

  getTotal = async (val) => {
    return new Promise((resolve, reject) => {
      var tempKeys = Object.keys(val);
      if (tempKeys.length == 0) return 0;
      var total = [];
      tempKeys.map((value, index) => {
        total.push(parseFloat(val[value].value));
        console.log(total);
        if (tempKeys.length == total.length) {
          return resolve(total.reduce((a, b) => a + b, 0));
        }
      });
    });
  };

  approveCredit = (index) => {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/approveCreditFall?key=" +
          this.props.match.params.uid +
          "&index=" +
          index,
          {
            headers: {
              idtoken: idToken,
            },
          }
        )
          .then((response) => response.json())
          .then(async (data) => {
            if (data.success) {
              this.fetchData();
            }
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.6" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.7" + error
        );
      });
  };

  renderTable = () => {
    this.state.creditsKeys.map((value, index) => {
      console.log("hiiii");
      console.log(this.state.credits[value].verify);
      console.log(this.state.credits[value].verify == true);
    });

    if (this.state.creditsKeys.length > 0) {
      return (
        <>
          {this.state.creditsKeys.map((value, index) => (
            <tr
              class={
                (this.state.credits[value].verify != false)
                  ? (String(this.state.credits[value].verify).split(";").length < 3 && typeof (this.state.credits[value].verify) != "boolean")
                    ? (!String(this.state.credits[value].verify).includes("@mypisd.net"))
                      ? "bg-yellow-50"
                      : "bg-gray-50"
                    : "bg-green-50"
                  : "bg-red-50"
              }
            >
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                {this.state.credits[value].name}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500 max-w-md">
                {this.state.credits[value].desc}
              </td>
              <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 text-gray-500">
                {this.state.credits[value].system ? (
                  <span class="text-indigo-600 hover:text-indigo-900 focus:outline-none">
                    <>
                      <svg
                        className="h-5 w-5 text-indigo-600 group-hover:text-gray-500 group-focus:text-gray-500 transition ease-in-out duration-150"
                        style={{ display: "inline-flex" }}
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24 "
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                        ></path>
                      </svg>
                      Tracked through peshnhs.org
                    </>
                  </span>
                ) : (
                  <a
                    href={
                      "https://media.peshnhs.org/media/" +
                      this.state.credits[value].file
                    }
                    target="_blank"
                    class="text-indigo-600 hover:text-indigo-900 focus:outline-none"
                  >
                    Uploaded Photo
                  </a>
                )}
              </td>
              <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                {this.state.credits[value].value}
              </td>
              <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium">
                {(String(this.state.credits[value].verify).split(";").length >= 3 || this.state.credits[value].verify == true) ? (
                  <>
                    <a
                      class="text-red-600"
                      onClick={() =>
                        this.setState({ modal: true, index: value })
                      }
                    >
                      <svg
                        class="w-8 h-8"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </a>
                    <a
                      class="text-green-600"
                      onClick={() =>
                        this.setState({ modalNote: true, index: value })
                      }
                    >
                      <svg
                        class="w-8 h-8"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      class="text-green-600"
                      onClick={() => this.approveCredit(value)}
                    >
                      <svg
                        class="w-8 h-8"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </a>
                    <a
                      class="text-red-600"
                      onClick={() =>
                        this.setState({ modal: true, index: value })
                      }
                    >
                      <svg
                        class="w-8 h-8"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </a>
                  </>
                )}
              </td>
            </tr>
          ))}
        </>
      );
    }
  };

  startEdit = (index) => {
    this.setState({
      editing: true,
      editInd: index,
      name: this.state.credits[index].name,
      value: this.state.credits[index].value,
      desc: this.state.credits[index].desc,
      file: this.state.credits[index].file,
    });
  };

  startModal = (index) => {
    this.setState({
      modal: true,
      name: "",
      value: "",
      desc: "",
      file: "",
    });
  };

  handleSubmitModal = (e) => {
    e.preventDefault();
    if (this.state.file == "") {
      alert("Please upload a photo for proof before submitting.");
    } else {
      firebase
        .database()
        .ref("credits/" + firebase.auth().currentUser.uid)
        .push({
          name: this.state.name,
          value: this.state.value,
          desc: this.state.desc,
          file: this.state.file,
        })
        .then(() => {
          this.setState({
            modal: false,
            name: firebase.auth().currentUser,
            desc: "",
            value: "",
            file: "",
          });
        });
    }
  };

  handleEditModal = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .set({
        name: this.state.name,
        value: this.state.value,
        desc: this.state.desc,
        file: this.state.file,
      })
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  denyCreditFall = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/denyCreditFall" + "?fair=peshfair",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              idtoken: idToken,
            },
            body: JSON.stringify({
              key: this.props.match.params.uid,
              index: this.state.index,
              name: this.state.credits[this.state.index].name,
              message: this.state.message,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              modal: false,
            });
            this.fetchData();
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.8" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.9" + error
        );
      });
  };

  renderModal = () => {
    return (
      <form onSubmit={this.denyCreditFall}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modal: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Deny Reason
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    In order to deny this credit, please supply a reason that is
                    emailed to the member.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to member:
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      required
                      rows="3"
                      onChange={this.handleChanges}
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() => this.setState({ modal: false })}
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  noteCredit = (e) => {
    e.preventDefault();
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org/noteCredit" + "?fair=peshfair",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              idtoken: idToken,
            },
            body: JSON.stringify({
              key: this.props.match.params.uid,
              index: this.state.index,
              name: this.state.credits[this.state.index].name,
              message: this.state.message,
            }),
          }
        )
          .then((response) => response.json())
          .then((data) => {
            this.setState({
              modalNote: false,
            });
          })
          .catch((error) => {
            alert(
              "Activation error. Try signing out and signing in again.10" + error
            );
          });
      })
      .catch(function (error) {
        alert(
          "Activation error. Try signing out and signing in again.11" + error
        );
      });
  };

  renderModalNote = () => {
    return (
      <form onSubmit={this.noteCredit}>
        <div class="fixed bottom-0 top-0 left-0 px-4 py-4 inset-x-0 sm:flex sm:items-center sm:justify-center overflow-y-auto">
          <div class="fixed inset-0 transition-opacity">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>

          <div class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6">
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                onClick={() => this.setState({ modalNote: false })}
                type="button"
                class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
              >
                <svg
                  class="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  class="folder-add w-6 h-6 text-blue-600"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2h-5L9 4H4zm7 5a1 1 0 00-2 0v1H8a1 1 0 000 2h1v1a1 1 0 002 0v-1h1a1 1 0 000-2h-1V9z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900">
                  Send a Note
                </h3>
                <div class="mt-2">
                  <p class="text-sm leading-5 text-gray-500">
                    In order to send a note, please supply a message that is
                    emailed to the member.
                  </p>
                </div>
              </div>
            </div>
            <>
              <div class="grid grid-cols-6 gap-3 pt-4">
                <div class="col-span-6 sm:col-span-6">
                  <p class="mt-0 text-sm text-gray-500">
                    Message to send to member:
                  </p>
                  <div class="rounded-md shadow-sm">
                    <textarea
                      id="message"
                      name="message"
                      required
                      rows="3"
                      onChange={this.handleChanges}
                      class="form-textarea mt-1 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                      placeholder=""
                    ></textarea>
                  </div>
                </div>
              </div>
            </>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Send
                </button>
              </span>
              <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                <button
                  onClick={() =>
                    this.setState({ modalNote: false, message: "" })
                  }
                  type="button"
                  class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Cancel
                </button>
              </span>
            </div>
          </div>
        </div>
      </form>
    );
  };

  handleDeleteModal = () => {
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .remove()
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleChanges = (e) => {
    this.setState({ [e.target.id]: e.target.value }, () => { });
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {this.state.modal && this.renderModal()}
        {this.state.modalNote && this.renderModalNote()}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <NavLink
            to="/students/"
            exact
            className="hidden text-gray-900 hover:text-gray-700 md:block"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </NavLink>
          <h1 className="text-2xl font-semibold text-gray-900">
            Credit Log (Fall) for {this.state.name}
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div class="flex flex-col">
              <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                  <table class="min-w-full">
                    <thead>
                      <tr>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Description
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Proof
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                          Credits
                        </th>
                        <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      {this.renderTable()}
                      <tr>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                          <b>Total</b>
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500"></td>
                        <td class="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-sm leading-5 text-gray-500">
                          {this.state.total}
                        </td>
                        <td class="px-6 py-4 whitespace-no-wrap text-right border-b border-gray-200 text-sm leading-5 font-medium"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="bg-white shadow sm:rounded-lg mt-4">
              <div class="p-4 bg-white shadow sm:rounded-lg my-4">
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    Grant Service Hours
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm leading-5 text-gray-500 px-4">
                      Use for adjusts or hour corrections.
                    </p>
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-3/4 p-2">
                    <label>Service Title</label>
                    <input
                      class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"

                      onChange={(e) => { this.setState({ title: e.target.value }) }}
                    />
                  </div>
                  <div class="w-1/4 p-2">
                    <label>Hour Amount</label>
                    <input
                      type="number"

                      onChange={(e) => this.setState({ hours: parseFloat(e.target.value) })}
                      class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    />
                  </div>
                </div>
                <div class="p-2">
                  <label>Description</label>
                  <input
                    class="h-1/4 mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                    onChange={(e) => { this.setState({ description: e.target.value }) }}
                  />
                </div>
                <button
                  onClick={this.grantHoursIndividual}
                  type="submit"
                  class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-gray-700 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default withRouter(App);
