import React from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';
import 'firebase/compat/database';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";

// ***Page Description***
// Page to manage events, displays table of events and their QR code
// ***********************
export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      events: {},
      eventsKeys: [],
      name: "",
      desc: "",
      slug: "",
      attendees: {},
      editing: false,
      editInd: null,
      rankedKeys: [],
    };
  }

  componentDidMount = () => {
    firebase
      .database()
      .ref("events")
      .on("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          const object = { ...snapshot.val() };

          this.setState({
            events: snapshot.val(),
            eventsKeys: Object.keys(snapshot.val()),
          });
        } else {
          this.setState({ events: {}, eventsKeys: [] });
        }
      });
  };

  // GRANT HOURSSSSSSSSSSS :DDDDDD
  grantHours = (eventName) => {
    // console.log(eventName);
    // const db_name = this.state.events[eventName].slug;
    // const event_name = this.state.events[eventName].name;
    // const event_desc = this.state.events[eventName].desc;
    // const conditional = this.state.events[eventName].conditional;
    // const currentDate = new Date();
    // const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    // const day = String(currentDate.getDate()).padStart(2, '0');
    // const year = String(currentDate.getFullYear()).slice(-2);
    // const date_approval = `${month}/${day}/${year}`;
    // const sem = "credits" // for fall
    // const credits = this.state.events[eventName].credits;
    // if (this.state.events[eventName].log == "spring") {
    //   sem = "creditsspring" // for spring
    // }
    // if (this.state.events[eventName].conditionals.minimums != null) {
    //   var minimums = this.state.events[eventName].conditionals.minimums;
    //   minimums.sort((a, b) => b[0] - a[0]); //greatest to least
    // } else {
    //   var minimums = null;
    // }
    // if (this.state.events[eventName].conditionals.intermediates != null) {
    //   var intermediates = this.state.events[eventName].conditionals.intermediates;
    // } else {
    //   var intermediates = null;
    // }
    // if (this.state.events[eventName].conditionals.maximums != null) {
    //   var maximums = this.state.events[eventName].conditionals.maximums;
    //   maximums.sort((a, b) => a[1] - b[1]); //least to greatest
    // } else {
    //   var maximums = null;
    // }
    // if (this.state.events[eventName].attendees != null) {
    //   var attendees = Object.keys(this.state.events[eventName].attendees);
    // } else {
    //   alert("No attendees found.")
    //   return;
    // }
    // // [0,0] - hours then credits
    // // Math.round(num * 2) / 2; round to nearest half
    // for (let i in attendees) {
    //   let hours = 0;

    //   if (conditional == "no") {
    //     console.log(attendees);
    //     console.log(i);
    //     console.log(this.state.events[eventName].attendees);
    //     let timein = this.state.events[eventName].attendees[attendees[i]].timein;
    //     let timeout = this.state.events[eventName].attendees[attendees[i]].timeout;
    //     if (timeout != null) {
    //       let totalTime = (timeout - timein) / (1000*60*60); // convert to hours
    //       let roundedTime = Math.round(totalTime * 2) / 2; // round to nearest half
    //       hours = roundedTime;
    //     } else {
    //       hours = credits;
    //     }
    //   } else {
    //     let timein = this.state.events[eventName].attendees[attendees[i]].timein;
    //     let timeout = this.state.events[eventName].attendees[attendees[i]].timeout;
    //     if (timeout != null) {
    //       let totalTime = (timeout - timein) / (1000*60*60); // convert to hours
    //       let roundedTime = Math.round(totalTime * 2) / 2; // round to nearest half
          
    //       if (minimums != null) {
    //         for (let x in minimums) {
    //           if (roundedTime < x[0]) {
    //             hours = x[1]
    //           }
    //         }
    //       }
    //       if (maximums != null) {
    //         for (let x in maximums) {
    //           if (roundedTime > x[0]) {
    //             hours = x[1]
    //           }
    //         }
    //       }
    //       if (intermediates != null) {
    //         for (let x in intermediates) {
    //           if (roundedTime == x[0]) {
    //             hours = x[1]
    //           }
    //         }
    //       }
    //     } else {
    //       hours = credits;
    //     }
    //   }

    //   firebase
    //     .database()
    //     .ref(`${sem}/${attendees[i]}/${db_name}`)
    //     .update({
    //       name: event_name,
    //       value: hours,
    //       desc: event_desc,
    //       verify: true,
    //       system: true,
    //     })
    //     .then(() => {
    //       console.log(`Updated credits for: ${i}`);

    //     }).catch((error) => {
    //       console.error(`Error updating credits for ${i}:`, error);
    //     });
    // }
    // alert("Updated all hours!")
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then((idToken) => {
        fetch(
          "https://api.peshnhs.org//grantHoursFunction?event=" +
            eventName,
          {
            headers: {
              idtoken: idToken,
            },
          }
      )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Successfully updated all hours for this event: " + eventName)
        } else {
          alert("There was an error when updating hours.")
        }
      })
      .catch((error) => {
        alert("Activation error. Try signing out and signing in again.");
        console.log(error);
      });
    })
    .catch(function (error) {
      alert("Activation error. Try signing out and signing in again.");
      console.log(error);
    });
  } 

  addEvent = (e) => {
    if (this.state.slug != "") {
    firebase
      .database()
      .ref("events/" + this.state.slug)
      .update({
        name: this.state.slug,
        slug: this.state.slug,
        desc: "",
        credits: 0,
        codes: {},
        attendees: {},
        conditional: "no",
        log: "fall",
        conditionals: {
          minimums: [],
          intermediates: [],
          maximums: [],
        }
      })
      .then(() => {
        window.location = "/events/" + this.state.slug;
      });
  }
  else {
    alert("Name cannot be empty.")
  }
};

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  renderTable = () => {
    if (this.state.eventsKeys.length > 0) {
      return (
        <>
          {this.state.eventsKeys.map((value, index) => {
            return (
              <tr>
                <td class="px-6 py-4 border-b border-gray-200 text-sm leading-5 font-medium text-gray-900">
                  {this.state.events[value].slug}
                </td>

                <td className="px-6 py-4 text-right border-b border-gray-200 text-sm leading-5 font-medium">
                {/* Red Grant Hours Button */}
                <button
                  onClick={() => this.grantHours(this.state.events[value].slug)}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out mr-2"
                >
                  Grant Hours
                </button>

                {/* Blue Manage NavLink styled as a button */}
                <NavLink
                  to={`/events/${value}`}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
                >
                  Manage
                </NavLink>
              </td>
                
              </tr>
            );
          })}
        </>
      );
    }
  };

  render() {
      return (
        <main
          className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
          tabindex="0"
          x-data
          x-init="$el.focus()"
        >
          {this.state.modal && this.renderEdit()}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900 ">
              Event Viewer
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <div class="flex flex-col">
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Add Event
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Type event name at the bottom of the table and click
                          add.
                        </p>
                      </div>
                    </div>
                  </a>
                </div>

                <div class="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8 mt-3">
                  <h2 className="text-lg font-semibold text-gray-900 ml-2 mb-2">
                    All Events
                  </h2>
                  <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                            Name
                          </th>
                          <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white">{this.renderTable()}</tbody>
                    </table>
                    <div class="flex justify-start p-2">
                      <div class="justify-start flex-1">
                        <div class="col-span-2 flex align-center">
                          <input
                            id="slug"
                            name="slug"
                            value={this.state.slug}
                            onChange={this.handleChanges}
                            class="form-input block w-64 py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                          <span class="ml-3 rounded-md justify-end flex-1">
                            <button
                              onClick={(e) => this.addEvent(e)}
                              placeholder="Block name"
                              class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                            >
                              Add Event
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
  }
}
