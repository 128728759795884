import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';


import SignIn from "./components/signin";
import Main from "./components/HomeSidebar";
import AccountDomain from "./components/accountDomain";

const firebaseConfig = {
  apiKey: "AIzaSyC3DOZIo3IjTJdDK7sutkg3Guz-dE_wS1g",
  authDomain: "peshnhs2025.firebaseapp.com",
  databaseURL: "https://peshnhs2025-default-rtdb.firebaseio.com",
  projectId: "peshnhs2025",
  storageBucket: "peshnhs2025.appspot.com",
  messagingSenderId: "658898231687",
  appId: "1:658898231687:web:0807cfaf9ce30fd5b0f322",
  measurementId: "G-B4XN4HQWLV"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: null,
      active: false,
      shouldRedirect: false,
    };
  }

  uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google as auth providers.
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,
    },
  };

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onIdTokenChanged((user) => {
      if (!!user) {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            console.log(idTokenResult);
            console.log(idTokenResult.token);
            if (idTokenResult.claims.role != null) {
              this.setState({ isSignedIn: true, role: idTokenResult.claims.role, email: user.email});
            } else {
              this.setState({
                isSignedIn: true,
                role: 0,
                shouldRedirect: true,
                email: user.email
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({ isSignedIn: !!user });
      }
    });
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (this.state.isSignedIn == null) {
      return (
        <div class="lds-default">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );
    } else if (this.state.isSignedIn == false) {
      return <SignIn />;
    } else if (this.state.email.indexOf("@mypisd.net") == -1 && this.state.email.indexOf("@pisd.edu") == -1) {
      return <AccountDomain />;
    }
    return <Main role={this.state.role} category={this.state.category} entry={this.state.entry} email={this.state.email}/>;
  }
}
