import React from "react";
import { Link, NavLink } from "react-router-dom";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth';import { withRouter } from "react-router";

// ***Page Description***
// Page to manage a specific event, allowing to set for name, hour amounts, etc.
// ***********************
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      name: "",
      slug: "",
      desc: "",
      credits: 0,
      codes: {},
      attendees: {},
      conditional: "no",
      log: "fall",
      conditionals: {
        minimums: [],
        intermediates: [],
        maximums: [],
      }
    };
  }

  componentDidMount = () => {
    this.fetchData();
  };

  fetchData = () => {
    firebase
      .database()
      .ref("events/" + this.props.match.params.id)
      .once("value", (snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val() != null) {
          const object = { ...snapshot.val() };
          this.setState({
            ...object,
          });
        } else {
          this.setState({ name: 0 });
        }
      });
  };

  startEdit = (index) => {
    this.setState({
      editing: true,
      editInd: index,
      name: this.state.credits[index].name,
      value: this.state.credits[index].value,
      desc: this.state.credits[index].desc,
      file: this.state.credits[index].file,
    });
  };

  startModal = (index) => {
    this.setState({
      modal: true,
      name: "",
      value: "",
      desc: "",
      file: "",
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.slug == "") {
      alert("Please add a slug.");
    } else {
      firebase
        .database()
        .ref("events/" + this.props.match.params.id)
        .update(this.state)
        .then(() => {
          alert("Saved!");
        });
    }
  };

  handleEditModal = (e) => {
    e.preventDefault();
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .set({
        name: this.state.name,
        value: this.state.value,
        desc: this.state.desc,
        file: this.state.file,
      })
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  handleDeleteModal = () => {
    firebase
      .database()
      .ref(`credits/${firebase.auth().currentUser.uid}/${this.state.editInd}`)
      .remove()
      .then(() => {
        this.setState({
          editing: false,
          name: "",
          desc: "",
          value: "",
          file: this.state.file,
        });
      });
  };

  addMinimum = (e) => {
    e.preventDefault(); // Prevent form submission
    
    // Check if conditionals.minimums exists, if not initialize it as an empty array
    this.setState((prevState) => ({
      conditionals: {
        ...prevState.conditionals,
        minimums: prevState.conditionals.minimums ? 
          [...prevState.conditionals.minimums, [0, 0]] : [[0, 0]],
      },
    }), () => {
      console.log(this.state);
    });
  };
  
  addIntermediate = (e) => {
    e.preventDefault(); // Prevent form submission
    
    // Check if conditionals.intermediates exists, if not initialize it as an empty array
    this.setState((prevState) => ({
      conditionals: {
        ...prevState.conditionals,
        intermediates: prevState.conditionals.intermediates ? 
          [...prevState.conditionals.intermediates, [0, 0]] : [[0, 0]],
      },
    }), () => {
      console.log(this.state);
    });
  };
  
  addMaximum = (e) => {
    e.preventDefault(); // Prevent form submission
    
    // Check if conditionals.maximums exists, if not initialize it as an empty array
    this.setState((prevState) => ({
      conditionals: {
        ...prevState.conditionals,
        maximums: prevState.conditionals.maximums ? 
          [...prevState.conditionals.maximums, [0, 0]] : [[0, 0]],
      },
    }), () => {
      console.log(this.state);
    });
  };
  
  

  handleInputChange = (e, type, index) => {
    const { name, value } = e.target;
    const newConditionals = { ...this.state.conditionals };

    // Update the appropriate conditional array
    if (type === "minimums") {
        newConditionals.minimums[index][name === "hours" ? 0 : 1] = value;
    } else if (type === "intermediates") {
        newConditionals.intermediates[index][name === "hours" ? 0 : 1] = value;
    } else if (type === "maximums") {
        newConditionals.maximums[index][name === "hours" ? 0 : 1] = value;
    }

    this.setState({ conditionals: newConditionals });
};

deleteCard = (type, index) => {
    const newConditionals = { ...this.state.conditionals };

    if (type === "minimums") {
        newConditionals.minimums.splice(index, 1);
    } else if (type === "intermediates") {
        newConditionals.intermediates.splice(index, 1);
    } else if (type === "maximums") {
        newConditionals.maximums.splice(index, 1);
    }

    this.setState({ conditionals: newConditionals });
};

  

  renderOptions = () => {
    return (
      <>
        {Object.keys(this.state.script).map((value, index) => {
          return (
            <>
              <option value={value}>{value}</option>
            </>
          );
        })}
      </>
    );
  };

  handleChanges = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      console.log(this.state);
    });
  };

  render() {
    return (
      <main
        className="flex-1 relative z-0 overflow-y-auto pt-2 pb-6 focus:outline-none md:py-6"
        tabindex="0"
        x-data
        x-init="$el.focus()"
      >
        {/* {this.state.actionModal && this.renderModal()} */}
        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <NavLink
            to="/events/"
            exact
            className="hidden text-gray-900 hover:text-gray-700 md:block"
          >
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </NavLink>
          <h1 className="text-2xl font-semibold text-gray-900">
            Configure {this.state.name}
          </h1>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <form onSubmit={this.handleSubmit}>
            <div className="py-4">
              <div>
                <div class="bg-blue-700 dark:bg-blue-900 shadow hover:shadow-lg transition duration-150 ease-in-out lg:rounded-lg w-full">
                  <a class="p-8 block sm:flex sm:items-center sm:justify-between">
                    <div>
                      <h3 class="text-2xl leading-7 font-medium text-white dark:text-dark-high-emphasis">
                        Edit this event
                      </h3>
                      <div class="mt-2 leading-5 text-teal-200 dark:text-teal-300">
                        <p>
                          Edit the fields below. 
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
                <div class="mt-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Descriptors
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Descriptive details of the event.
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Name
                          </label>
                          <input
                            required
                            id="slug"
                            name="slug"
                            value={this.state.slug}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-4">
                          <label
                            for="email_address"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Description
                          </label>
                          <input
                            required
                            id="desc"
                            name="desc"
                            value={this.state.desc}
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <div class="col-span-6 sm:col-span-3">
                          <label
                            for="first_name"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Credits
                          </label>
                          <input
                            id="credits"
                            required
                            name="credits"
                            value={this.state.credits}
                            type="number"
                            step="0.5"
                            min="0"
                            onChange={this.handleChanges}
                            class="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          />
                        </div>

                        <br></br>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="log"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Log
                          </label>
                          <select
                            id="log"
                            name="log"
                            value={this.state.log}
                            onChange={this.handleChanges}
                            className="mt-1 form-select block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option value="">Select an option</option>
                            <option value="spring">Spring</option>
                            <option value="fall">Fall</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br></br>




                  <div class="md:grid md:grid-cols-3 md:gap-6">
                    <div class="md:col-span-1">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">
                        Conditionals
                      </h3>
                      <p class="mt-1 text-sm leading-5 text-gray-500">
                        Add conditionals to this event. 
                      </p>
                    </div>
                    <div class="mt-5 md:mt-0 md:col-span-2">
                      <div class="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="conditional"
                            className="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Conditional
                          </label>
                          <select
                            id="conditional"
                            name="conditional"
                            value={this.state.conditional}
                            onChange={this.handleChanges}
                            className="mt-1 form-select block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                          >
                            <option value="">Select an option</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>

                        <div className="col-span-6 mt-4">
                          <button 
                            className="flex items-center w-full px-4 py-2 bg-red-500 text-white text-sm font-medium rounded hover:bg-red-600 focus:outline-none"
                            onClick={this.addMinimum}
                          >
                            <span className="mr-2">➕</span> Minimum
                          </button>
                          <button 
                            className="flex items-center w-full mt-2 px-4 py-2 bg-yellow-500 text-white text-sm font-medium rounded hover:bg-yellow-600 focus:outline-none"
                            onClick={this.addIntermediate}
                          >
                            <span className="mr-2">➕</span> Intermediate
                          </button>
                          <button 
                            className="flex items-center w-full mt-2 px-4 py-2 bg-green-500 text-white text-sm font-medium rounded hover:bg-green-600 focus:outline-none"
                            onClick={this.addMaximum}
                          >
                            <span className="mr-2">➕</span> Maximum
                          </button>
                        </div>

                        <div className="col-span-6 mt-4">
      <div>
        <h4>Minimums</h4>
        {this.state.conditionals.minimums != null ? (
          this.state.conditionals.minimums.map((min, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
              <h5 className="font-medium">Minimum</h5>
              <div className="flex flex-col">
                <label>Number of hours</label>
                <input
                  type="number"
                  name="hours"
                  value={min[0]}
                  onChange={(e) => this.handleInputChange(e, "minimums", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
                <label>Number of credits</label>
                <input
                  type="number"
                  name="credits"
                  value={min[1]}
                  onChange={(e) => this.handleInputChange(e, "minimums", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
              </div>
              <button
                className="mt-2 bg-red-500 text-white rounded py-1 px-4"
                onClick={() => this.deleteCard("minimums", index)}
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p>No minimums added.</p>
        )}
      </div>

      <div>
        <h4>Intermediates</h4>
        {this.state.conditionals.intermediates != null ? (
          this.state.conditionals.intermediates.map((int, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
              <h5 className="font-medium">Intermediate</h5>
              <div className="flex flex-col">
                <label>Number of hours</label>
                <input
                  type="number"
                  name="hours"
                  value={int[0]}
                  onChange={(e) => this.handleInputChange(e, "intermediates", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
                <label>Number of credits</label>
                <input
                  type="number"
                  name="credits"
                  value={int[1]}
                  onChange={(e) => this.handleInputChange(e, "intermediates", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
              </div>
              <button
                className="mt-2 bg-red-500 text-white rounded py-1 px-4"
                onClick={() => this.deleteCard("intermediates", index)}
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p>No intermediates added.</p>
        )}
      </div>



      <div>
        <h4>Maximums</h4>
        {this.state.conditionals.maximums != null ? (
          this.state.conditionals.maximums.map((max, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
              <h5 className="font-medium">Maximum</h5>
              <div className="flex flex-col">
                <label>Number of hours</label>
                <input
                  type="number"
                  name="hours"
                  value={max[0]}
                  onChange={(e) => this.handleInputChange(e, "maximums", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
                <label>Number of credits</label>
                <input
                  type="number"
                  name="credits"
                  value={max[1]}
                  onChange={(e) => this.handleInputChange(e, "maximums", index)}
                  className="mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none"
                />
              </div>
              <button
                className="mt-2 bg-red-500 text-white rounded py-1 px-4"
                onClick={() => this.deleteCard("maximums", index)}
              >
                Delete
              </button>
            </div>
          ))
        ) : (
          <p>No maximums added.</p>
        )}
      </div>
    </div>





                      </div>
                    </div>
                  </div>
                  

                  <div class="mt-8 border-t border-gray-200 pt-5">
                    <div class="flex justify-between">
                      <div class="">
                        <span class="ml-3 inline-flex rounded-md shadow-sm">
                          <button class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out">
                            Save
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </main>
    );
  }
}
export default withRouter(App);
